




import { Component, Vue } from 'vue-property-decorator'
import RecommendationList from '@/views/recommendation/components/RecommendationList.vue'

@Component({
  name: 'outgoingClientList',
  components: {
    RecommendationList
  }
})

export default class extends Vue {}
